import { mapMutations } from 'vuex';
import { RootMutations } from '@/shared/store/types';
export default {
  beforeMount() {
    window.addEventListener('keydown', this.saveListener);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.saveListener);
  },
  methods: {
    ...mapMutations([RootMutations.SET_SAVE_SHORTCUT]),
    saveListener(event) {
      if (event.key == 'F7') {
        event.preventDefault();
        this[RootMutations.SET_SAVE_SHORTCUT](true);
      } else {
        this[RootMutations.SET_SAVE_SHORTCUT](false);
      }
    }
  }
};
