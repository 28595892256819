<template>
  <div>
    <div v-if="isLoading" class="text-center py-4">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <div v-if="isOpsAdmin" class="d-flex mt-1">
        <b-form-checkbox
          v-model="inOpsForecast"
          class="mt-3"
          size="md"
          switch
          @change="toggleUnitOpsForecastTracking()"
        ></b-form-checkbox>
        <h4 class="mt-3">Track in Operations Forecast</h4>
      </div>
      <div v-else-if="hasStandardOperations && unit.showInOpsForecast">
        <h4 class="mt-3">Unit Tracked in Operations Forecast</h4>
      </div>
      <div v-else-if="hasStandardOperations && !unit.showInOpsForecast">
        <h4 class="mt-3">Unit Not Tracked in Operations Forecast</h4>
      </div>
      <div :class="!isOpsAdmin ? 'readonly-unit-operations-header' : 'unit-operations-header'">
        <b-form-group
          v-if="isOpsAdmin"
          class="addOperationsDropdown mt-2"
          label="Scheduled Operations:"
          label-for="operations"
        >
          <div class="d-flex mb-2">
            <v-select
              id="operations"
              v-model="stdOpsToAdd"
              class="mr-2"
              :options="availableStandardOperations"
              :label="`name`"
              :close-on-select="false"
              multiple
            >
              <template #option="operation">
                <span class="text-uppercase">{{ operation.name }}</span>
              </template>
              <template #selected-option="operation">
                <span class="text-uppercase">{{ operation.name }}</span>
              </template>
            </v-select>
            <div>
              <b-button :disabled="stdOpsToAdd.length < 1" @click="add(stdOpsToAdd)">Add</b-button>
            </div>
          </div>
        </b-form-group>
        <div v-if="hasPMContractUnitOperation" class="pm-contract-info">
          <div v-if="dateContractStart && dateContractEnd" class="mr-5">
            PM CONTRACT DATE RANGE: {{ dateContractStart | date }} -
            {{ dateContractEnd | date }}
          </div>
          <div v-else class="mr-5">PM CONTRACT DATE RANGE: NOT SPECIFIED</div>
          <b-form-checkbox v-model="filterByContractJobs" switch class="mt-1">PM Contract Operations</b-form-checkbox>
        </div>
      </div>
      <div class="clearfix"></div>

      <div v-if="localUnitStandardOperations.length" class="mt-4">
        <b-table
          striped
          no-border-collapse
          sticky-header="90vh"
          :items="
            filterByContractJobs
              ? Object.values($v.localUnitStandardOperations.$each.$iter).filter(
                  it => it.$model.isPMContractUnitOperation
                )
              : Object.values($v.localUnitStandardOperations.$each.$iter)
          "
          :fields="!$isCustomer ? fields : vcpFields"
          class="border align-middle"
          primary-key="$model.standardOperationId"
        >
          <!-- Excede Job Dropdown Column -->
          <template #cell(excedeJobId)="{ item }">
            <v-select
              v-if="isOpsAdmin"
              v-model="item.$model.excedeJob"
              :filter-by="excedeJobDropdownSearchFilter"
              placeholder="Select"
              :options="item.$model.excedeJobOptions"
              :clearable="false"
              label="excedeJobId"
              select-on-tab
            >
              <template #option="excedeJob">
                <span class="text-uppercase">{{ excedeJob.excedeJobId }} - {{ excedeJob.description }}</span>
              </template>
              <template #selected-option="selectedExcedeJob">
                <span class="text-uppercase">
                  {{ selectedExcedeJob.excedeJobId }}
                </span>
              </template>
            </v-select>
            <div v-else>
              <p>{{ excedeJobDisplay(item.$model.excedeJob) }}</p>
            </div>
          </template>

          <template #cell(duration)="{ item }">
            <div class="d-flex flex-wrap">
              <div class="mb-2 mr-auto ml-auto">
                <label
                  class="col-head w-100"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.durationStatus, 'Due')"
                >
                  Due:
                  <b-badge v-if="item.$model.durationStatus === 'Due'" class="w13px float-right" variant="warning">
                    !
                  </b-badge>
                </label>
                <div v-if="!isOpsAdmin">{{ item.$model.durationDue || NA }}</div>
                <b-input
                  v-else
                  v-model.number="item.durationDue.$model"
                  class="mr-2"
                  :state="$v.$dirty && hasError(item.durationDue) ? false : null"
                  type="number"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.durationStatus, 'Due')"
                  :disabled="!isOpsAdmin"
                  @keypress="NumberFieldHelper.isNumber"
                />
              </div>
              <div class="mb-2 mr-auto ml-auto">
                <label
                  class="col-head w-100"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.durationStatus, 'Overdue')"
                >
                  Overdue:
                  <b-badge v-if="item.$model.durationStatus === 'Overdue'" class="w13px float-right" variant="danger">
                    !
                  </b-badge>
                </label>
                <div
                  v-if="!isOpsAdmin"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.durationStatus, 'Overdue')"
                >
                  {{ item.$model.durationOverdue || NA }}
                </div>
                <b-input
                  v-else
                  v-model.number="item.durationOverdue.$model"
                  :state="$v.$dirty && hasError(item.durationOverdue) ? false : null"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.durationStatus, 'Overdue')"
                  :disabled="!isOpsAdmin"
                  type="number"
                  @keypress="NumberFieldHelper.isNumber"
                />
              </div>
            </div>
            <div v-if="$v.$dirty && (item.durationDue.$error || item.durationOverdue.$error)" class="error">
              {{ getErrorMessage(item.durationDue, item.durationOverdue) }}
            </div>
          </template>
          <template #cell(mileage)="{ item }">
            <div class="d-flex flex-wrap">
              <div class="mb-2 mr-auto ml-auto">
                <label
                  class="col-head w-100"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.mileageStatus, 'Due')"
                >
                  Due:
                  <b-badge v-if="item.$model.mileageStatus === 'Due'" class="w13px float-right" variant="warning">
                    !
                  </b-badge>
                </label>
                <div v-if="!isOpsAdmin">{{ item.mileageDue.$model || NA }}</div>
                <b-input
                  v-else
                  v-model.number="item.mileageDue.$model"
                  class="mr-2"
                  :state="$v.$dirty && hasError(item.mileageDue) ? false : null"
                  :disabled="!isOpsAdmin"
                  type="number"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.mileageStatus, 'Due')"
                  @keypress="NumberFieldHelper.isNumber"
                />
              </div>
              <div class="mr-auto ml-auto">
                <label
                  class="col-head w-100"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.mileageStatus, 'Overdue')"
                >
                  Overdue:
                  <b-badge v-if="item.$model.mileageStatus === 'Overdue'" class="w13px float-right" variant="danger">
                    !
                  </b-badge>
                </label>

                <div
                  v-if="!isOpsAdmin"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.mileageStatus, 'Overdue')"
                >
                  {{ item.$model.mileageOverdue || NA }}
                </div>
                <b-input
                  v-else
                  v-model.number="item.mileageOverdue.$model"
                  class="mr-2"
                  :state="$v.$dirty && hasError(item.mileageOverdue) ? false : null"
                  :disabled="!isOpsAdmin"
                  type="number"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.mileageStatus, 'Overdue')"
                  @keypress="NumberFieldHelper.isNumber"
                />
              </div>
            </div>
            <div v-if="$v.$dirty && (item.mileageDue.$error || item.mileageOverdue.$error)" class="error">
              {{ getErrorMessage(item.mileageDue, item.mileageOverdue) }}
            </div>
          </template>
          <template #cell(engineHours)="{ item }">
            <div class="d-flex flex-wrap">
              <div class="mb-2 mr-auto ml-auto">
                <label
                  class="col-head w-100"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.engineHoursStatus, 'Due')"
                >
                  Due:
                  <b-badge v-if="item.$model.engineHoursStatus === 'Due'" class="float-right w13px" variant="warning">
                    !
                  </b-badge>
                </label>
                <div v-if="!isOpsAdmin">{{ item.engineHoursDue.$model || NA }}</div>
                <b-input
                  v-else
                  v-model.number="item.engineHoursDue.$model"
                  class="mr-2"
                  :state="$v.$dirty && hasError(item.engineHoursDue) ? false : null"
                  type="number"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.engineHoursStatus, 'Due')"
                  @keypress="NumberFieldHelper.isNumber"
                />
              </div>
              <div class="mr-auto ml-auto">
                <label
                  class="col-head w-100"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.engineHoursStatus, 'Overdue')"
                >
                  Overdue:
                  <b-badge
                    v-if="item.$model.engineHoursStatus === 'Overdue'"
                    class="w13px float-right"
                    variant="danger"
                  >
                    !
                  </b-badge>
                </label>
                <div v-if="!isOpsAdmin">{{ item.engineHoursOverdue.$model || NA }}</div>
                <b-input
                  v-else
                  v-model.number="item.engineHoursOverdue.$model"
                  class="mr-2"
                  :state="$v.$dirty && hasError(item.engineHoursOverdue) ? false : null"
                  :disabled="!isOpsAdmin"
                  type="number"
                  :class="CssHelper.getOperationCriterionStatusCssClass(item.$model.engineHoursStatus, 'Overdue')"
                  @keypress="NumberFieldHelper.isNumber"
                />
              </div>
            </div>
            <div v-if="$v.$dirty && (item.engineHoursDue.$error || item.engineHoursOverdue.$error)" class="error">
              {{ getErrorMessage(item.engineHoursDue, item.engineHoursOverdue) }}
            </div>
          </template>

          <template #cell(lastCompleted)="{ item }">
            <div v-if="item.$model.lastCompletedDate">{{ item.$model.lastCompletedDate | date }}</div>
            <div v-if="item.$model.lastCompletedMileage">{{ item.$model.lastCompletedMileage | number }} Miles</div>
            <div v-if="item.$model.lastCompletedEngineHours">
              {{ item.$model.lastCompletedEngineHours | number }} Hours
            </div>
          </template>

          <template #cell(nextDue)="{ item }">
            <div class="text-left d-flex flex-column">
              <div v-if="item.$model.durationDue && item.$model.lastCompletedDate">
                {{ getNextDueDate(item.$model) | date }}
              </div>
              <div v-if="item.$model.mileageDue && item.$model.lastCompletedMileage">
                {{ getNextDueMileage(item.$model) | number }} Miles
              </div>
              <div v-if="item.$model.engineHoursDue && item.$model.lastCompletedEngineHours">
                {{ getNextDueEngineHours(item.$model) | number }} Hours
              </div>
            </div>
          </template>
          <template #cell(contractJobs)="{ item }">
            <div v-if="item.$model.isPMContractUnitOperation" class="text-left">
              <div class="mb-3">TOTAL: {{ item.$model.totalContractJobs }}</div>
              <div>COMPLETED: {{ item.$model.completedContractJobs }}</div>
            </div>
          </template>

          <template #[`cell($model.operationStatus)`]="{ value, item }">
            <div class="text-nowrap">
              <b-badge v-show="value" :variant="CssHelper.getOperationStatusCssClass(value)" class="mr-1">
                {{ value }}
              </b-badge>
              <sup v-if="value == 'Undetermined'">
                <font-awesome-icon
                  :id="`undetermined_tooltip_${item.$model.standardOperationId}`"
                  icon="question-circle"
                  size="lg"
                  class="text-primary align-self-center"
                  display="inline-block"
                />
                <b-tooltip :target="`undetermined_tooltip_${item.$model.standardOperationId}`" triggers="hover">
                  Unable to forecast due to missing data.
                  {{ $isCustomer ? 'Contact Clarke/VehiCare representative to update.' : '' }}
                </b-tooltip>
              </sup>
            </div>
          </template>

          <template #cell(actions)="row">
            <div class="text-center">
              <b-dropdown size="xsm" right variant="secondary" no-caret boundary="viewport">
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-h" />
                </template>
                <b-dropdown-item v-if="isOpsAdmin" @click="remove(row.index)">Remove</b-dropdown-item>
                <b-dropdown-item
                  @click="openServiceOrderAside(row.item.$model.unitId, row.item.$model.standardOperationId)"
                >
                  Mark Complete
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </div>
      <div v-else>
        <h6 class="mt-3">No scheduled operations assigned to unit.</h6>
      </div>
      <div class="row no-gutters">
        <div v-show="isBusy">
          <b-button variant="primary" disabled>
            <b-spinner small></b-spinner>
            SAVING...
          </b-button>
        </div>
        <div v-show="!isBusy && isOpsAdmin">
          <b-button variant="primary" @click="clickSave">SAVE</b-button>
        </div>
      </div>
      <transition name="slide">
        <unit-service-order-history-aside
          v-if="unitServiceOrdersAsideShow"
          v-model="serviceOrderWithJob"
          :loading="asideIsBusy"
          class="fixed-right fixed-top"
          mark-complete
          @completed="serviceOrderCompleted"
        />
      </transition>
      <unsaved-changes-modal
        ref="UnsavedChangesModal"
        :has-changes="hasChanges"
        continue-btn-text="Continue With Unit"
      ></unsaved-changes-modal>
    </div>
  </div>
</template>

<script>
// components
import UnitServiceOrderHistoryAside from '@/shared/components/service-order/UnitServiceOrderHistoryAside';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import vSelect from 'vue-select';
import NextDueMixin from '@/shared/mixins/NextDueMixin';
// vuex
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { UnitActions, UnitGetters, UnitMutations } from '@/shared/store/unit/types';
import { UserGetters } from '@/shared/store/user/types';
import { LookupGetters } from '@/shared/store/lookup/types';
// helpers
import Cloner from '@/shared/helpers/cloner';
import CssHelper from '@/shared/helpers/operation-status-css-class-helper';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import DataHelper from '@/shared/helpers/data-helper';
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import { integer, minValue, requiredIf } from 'vuelidate/lib/validators';
import { lessThanOrEqual, hasError, getStandardOperationErrorMessage } from '@/shared/helpers/validator-helper.js';

export default {
  name: 'UnitScheduledOperationsPage',
  components: {
    'unit-service-order-history-aside': UnitServiceOrderHistoryAside,
    'unsaved-changes-modal': UnsavedChangesModal,
    vSelect
  },
  mixins: [NextDueMixin],
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges()) {
      this.$refs.UnsavedChangesModal.show(this, next);
    } else {
      next();
    }
  },
  data: function () {
    return {
      isLoading: false,
      CssHelper: CssHelper,
      NumberFieldHelper: NumberFieldHelper,
      NA: 'N/A',
      filterByContractJobs: false,
      vcpFields: [
        {
          key: '$model.name',
          label: 'Scheduled Operation',
          sortable: true,
          tdClass: 'align-middle',
          thClass: 'text-left'
        },
        { key: 'duration', label: 'Duration', tdClass: 'align-middle' },
        { key: 'mileage', label: 'Mileage', tdClass: 'align-middle' },
        { key: 'engineHours', label: 'Engine Hours', tdClass: 'align-middle' },
        { key: 'lastCompleted', label: 'Last Completed', sortable: false, tdClass: 'align-middle' },
        { key: 'nextDue', label: 'Next Due', sortable: false, tdClass: 'align-middle' },
        { key: '$model.operationStatus', label: 'Status', sortable: true, tdClass: 'align-middle' }
      ],
      isBusy: false,
      asideIsBusy: false,
      serviceOrderWithJob: {},
      localUnitStandardOperations: [],
      stdOpsToAdd: []
    };
  },
  validations: {
    localUnitStandardOperations: {
      $each: {
        durationDue: {
          integer,
          minValue: minValue(0),
          required: requiredIf('durationOverdue'),
          lessThanOrEqual: lessThanOrEqual('durationOverdue')
        },
        durationOverdue: {
          integer,
          minValue: minValue(0),
          required: requiredIf('durationDue')
        },
        mileageDue: {
          integer,
          minValue: minValue(0),
          required: requiredIf('mileageOverdue'),
          lessThanOrEqual: lessThanOrEqual('mileageOverdue')
        },
        mileageOverdue: {
          integer,
          minValue: minValue(0),
          required: requiredIf('mileageDue')
        },
        engineHoursDue: {
          integer,
          minValue: minValue(0),
          required: requiredIf('engineHoursOverdue'),
          lessThanOrEqual: lessThanOrEqual('engineHoursOverdue')
        },
        engineHoursOverdue: {
          integer,
          minValue: minValue(0),
          required: requiredIf('engineHoursDue')
        }
      }
    }
  },
  computed: {
    ...mapState('unit', ['unit', 'unitStandardOperations', 'unitServiceOrdersAsideShow']),
    ...mapGetters({
      unitLocationsMap: LookupGetters.GET_LOCATIONS_MAP,
      serviceOrderAsideShow: UnitGetters.GET_UNIT_SERVICE_ORDERS_ASIDE_SHOW,
      standardOperations: LookupGetters.GET_STANDARD_OPERATIONS_LIST,
      user: UserGetters.GET_USER_PROFILE
    }),
    ...mapGetters([UserGetters.AUTHORIZE_ROLE]),
    fields() {
      const fields = [];
      fields.push({
        key: '$model.name',
        label: 'Scheduled Operation',
        sortable: true,
        tdClass: 'align-middle',
        thClass: 'text-left'
      });
      fields.push({
        key: 'excedeJobId',
        label: 'Excede Job',
        sortable: false,
        tdClass: 'align-middle'
      });
      fields.push({
        key: 'duration',
        label: 'Duration',
        tdClass: 'align-middle',
        thClass: 'text-center',
        thStyle: 'width: 150px'
      });
      fields.push({
        key: 'mileage',
        label: 'Mileage',
        tdClass: 'align-middle',
        thClass: 'text-center',
        thStyle: 'width: 150px'
      });
      fields.push({
        key: 'engineHours',
        label: 'Engine Hours',
        tdClass: 'align-middle',
        thClass: 'text-center',
        thStyle: 'width: 140px'
      });
      fields.push({
        key: 'lastCompleted',
        label: 'Last Completed',
        sortable: false,
        tdClass: 'align-middle',
        thStyle: 'width: 140px'
      });
      fields.push({
        key: 'nextDue',
        label: 'Next Due',
        sortable: false,
        tdClass: 'align-middle',
        thStyle: 'width: 150px'
      });
      if (this.hasPMContractUnitOperation) {
        fields.push({
          key: 'contractJobs',
          label: 'Contract Jobs',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width: 140px'
        });
      }
      fields.push({ key: '$model.operationStatus', label: 'Status', sortable: true, tdClass: 'align-middle' });
      fields.push({ key: 'actions', label: 'Actions', tdClass: 'align-middle' });
      return fields;
    },
    isRegionalCSM() {
      return this[UserGetters.AUTHORIZE_ROLE](['RegionalCSM']);
    },
    isCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSM']);
    },
    hasStandardOperations() {
      return this.localUnitStandardOperations.length > 0;
    },
    hasPMContractUnitOperation() {
      return this.localUnitStandardOperations.find(job => job.isPMContractUnitOperation);
    },
    isOpsAdmin() {
      return !this.$isCustomer && (this.isRegionalCSM || (this.isCsm && this.hasBranchAccess)) && this.location;
    },
    hasBranchAccess() {
      const unitLocation = this.unitLocationsMap[this.unit.locationId];
      const unitBranchId = unitLocation?.branchId;
      let access = this.user.employeeBranches.some(branch => branch.branchId == unitBranchId);

      return access && !this.unit.isUnitAtVehicareBranch;
    },
    dateContractStart() {
      return this.localUnitStandardOperations.find(job => job.excedeJobId?.toLowerCase().includes('con'))
        ?.dateContractStart;
    },
    dateContractEnd() {
      return this.localUnitStandardOperations.find(job => job.excedeJobId?.toLowerCase().includes('con'))
        ?.dateContractEnd;
    },
    location() {
      return this.unitLocationsMap[this.unit.locationId];
    },
    inOpsForecast: {
      get() {
        return this.unit.showInOpsForecast;
      },
      set(value) {
        this[UnitMutations.SET_SHOW_IN_OPS_FORECAST](value);
      }
    },
    availableStandardOperations() {
      if (!this.standardOperations) return [];

      let filteredList = this.standardOperations.filter(
        standardOperation =>
          !this.localUnitStandardOperations.some(
            unitStandardOp => unitStandardOp.standardOperationId === standardOperation.standardOperationId
          )
      );

      if (filteredList.length == 0) {
        filteredList.push({
          standardOperationId: -1,
          name: 'No scheduled operations to add.',
          disabled: true
        });
      }

      return filteredList;
    }
  },
  watch: {
    unitStandardOperations: {
      handler(newValue) {
        this.localUnitStandardOperations = Cloner.deepClone(newValue);
      },
      immediate: true
    },
    serviceOrderAsideShow: {
      async handler(newValue) {
        if (!newValue) {
          await this.closedServiceOrderAside();
        }
      }
    }
  },
  async created() {
    this.isLoading = true;
    await this[UnitActions.FETCH_UNIT_STANDARD_OPERATIONS](this.unit.unitId);
    this.isLoading = false;
  },
  methods: {
    ...mapActions([
      UnitActions.FETCH_UNIT_STANDARD_OPERATION,
      UnitActions.FETCH_UNIT_STANDARD_OPERATIONS,
      UnitActions.UPDATE_UNIT_STANDARD_OPERATIONS,
      UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY,
      UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE,
      UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE,
      UnitActions.TOGGLE_SHOW_IN_OPS_FORECAST
    ]),
    ...mapMutations([UnitMutations.SET_SHOW_IN_OPS_FORECAST, UnitMutations.SET_PROP_DEFAULT]),
    async toggleUnitOpsForecastTracking() {
      try {
        this.isBusy = true;
        this.$v.$touch();
        if (!this.$v.$anyError) {
          await this[UnitActions.TOGGLE_SHOW_IN_OPS_FORECAST]({
            unitId: this.unit.unitId,
            showInOpsForecast: this.unit.showInOpsForecast
          });
          this[UnitMutations.SET_PROP_DEFAULT]({ key: 'showInOpsForecast', value: this.unit.showInOpsForecast });
          SuccessService.createSuccessToast(this.$root, `Saved successfully.`);
          this.$v.$reset();
        } else {
          const errorMessage = `Error encountered saving.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        }
      } finally {
        this.isBusy = false;
      }
    },
    async add(standardOperations) {
      this.localUnitStandardOperations.push(...standardOperations);
      this.stdOpsToAdd = [];
    },
    async serviceOrderCompleted() {
      await this[UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE]();
      await this[UnitActions.FETCH_UNIT_STANDARD_OPERATIONS](this.unit.unitId);
    },
    async loadServiceOrderData(unitId, standardOperationId) {
      this.asideIsBusy = true;
      await this[UnitActions.FETCH_UNIT_STANDARD_OPERATION]({ unitId, standardOperationId });
      await this[UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({
        unitId,
        params: { completedOnly: true }
      });
      this.asideIsBusy = false;
    },
    async openServiceOrderAside(unitId, standardOperationId) {
      this[UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE]();
      await this.loadServiceOrderData(unitId, standardOperationId);
    },
    async closedServiceOrderAside() {
      await this[UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({ unitId: this.unit.unitId });
    },
    remove(index) {
      this.localUnitStandardOperations.splice(index, 1);
    },
    async save() {
      try {
        this.isBusy = true;
        this.$v.$touch();
        if (!this.$v.$anyError) {
          let unitStandardOperationsToSave = transformUnitStandardOperationsToSave(this.localUnitStandardOperations);
          await this[UnitActions.UPDATE_UNIT_STANDARD_OPERATIONS](unitStandardOperationsToSave);
          await this[UnitActions.FETCH_UNIT_STANDARD_OPERATIONS](this.unit.unitId);

          SuccessService.createSuccessToast(this.$root, `Saved successfully.`);
          this.$v.$reset();
        } else {
          const errorMessage = `Error encountered saving.  Please see fields indicated below.`;
          ErrorService.createErrorToast(this, errorMessage);
          throw Error(errorMessage);
        }
      } finally {
        this.isBusy = false;
      }
    },
    async clickSave() {
      await this.save().catch(() => {
        this.isBusy = false;
      });
    },
    hasChanges: function () {
      const oldUnitStandardOperations = Cloner.deepClone(this.$store.getters[UnitGetters.GET_UNIT_STANDARD_OPERATIONS]);
      return (
        this.isOpsAdmin &&
        JSON.stringify(this.localUnitStandardOperations) !== JSON.stringify(oldUnitStandardOperations)
      );
    },
    excedeJobDisplay(excedeJob) {
      if (excedeJob) return `${excedeJob.excedeJobId} - ${excedeJob.description}`;
      return this.NA;
    },
    getErrorMessage: getStandardOperationErrorMessage,
    excedeJobDropdownSearchFilter: (option, label, search) => {
      search = search.toLowerCase();
      const excedeJobId = option.excedeJobId.toLowerCase();
      const description = option.description.toLowerCase();

      return excedeJobId.indexOf(search) > -1 || description.indexOf(search) > -1;
    },
    hasError: hasError
  }
};

function transformUnitStandardOperationsToSave(unitStandardOperations) {
  let clonedOperations = Cloner.deepClone(unitStandardOperations);

  for (const clonedOperation of clonedOperations) {
    clonedOperation.durationDue = DataHelper.transformEmptyToNull(clonedOperation.durationDue);
    clonedOperation.durationOverdue = DataHelper.transformEmptyToNull(clonedOperation.durationOverdue);

    clonedOperation.mileageDue = DataHelper.transformEmptyToNull(clonedOperation.mileageDue);
    clonedOperation.mileageOverdue = DataHelper.transformEmptyToNull(clonedOperation.mileageOverdue);

    clonedOperation.engineHoursDue = DataHelper.transformEmptyToNull(clonedOperation.engineHoursDue);
    clonedOperation.engineHoursOverdue = DataHelper.transformEmptyToNull(clonedOperation.engineHoursOverdue);

    delete clonedOperation.errorLabelDuration;
    delete clonedOperation.errorLabelMileage;
    delete clonedOperation.errorLabelEngineHours;
  }
  return clonedOperations;
}
</script>

<style scoped>
.addOperationsDropdown {
  width: 30vw;
}
.unit-operations-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.readonly-unit-operations-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.pm-contract-info {
  display: flex;
  align-items: center;
}
</style>
