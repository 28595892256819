<template>
  <div
    id="side-nav"
    class="vams-side-nav"
    :class="`${sidebar === SIDEBAR.COLLAPSED ? 'collapsed' : 'expanded'} ${$smallScreen ? 'mobile' : ''}`"
    @mouseleave="navbarMouseLeave"
    @click="clickNav"
  >
    <div v-if="!$smallScreen" class="nav-collapse-icon-container" @click="toggleExpandCollapse()">
      <font-awesome-icon
        :icon="sidebar === SIDEBAR.COLLAPSED || mouseEnterExpanded ? 'angle-right' : 'angle-left'"
        class="nav-collapse-icon"
        size="2x"
      />
    </div>
    <b-nav class="d-flex flex-column h-100" vertical @mouseenter="navbarMouseEnter">
      <b-nav-item v-if="reportsEnabled" router-link :to="setNavPath('/dashboard')" class="d-flex align-items-center">
        <div>
          <font-awesome-icon icon="chart-bar" size="lg" alt="Dashboard Reports" />
        </div>
        Dashboard Reports
      </b-nav-item>

      <b-nav-item router-link :to="setNavPath('/operation-forecast')" class="d-flex align-items-center">
        <div>
          <font-awesome-icon icon="wrench" size="lg" alt="Operation Forecast" />
        </div>
        Operation Forecast
      </b-nav-item>

      <b-nav-item router-link :to="setNavPath('/deferred-work')" class="position-relative py-2" link-classes="mx-auto">
        <div>
          <img src="/images/deferred-work.svg" :width="width" :height="height" alt="Deferred Work" />
        </div>
        Deferred Work
      </b-nav-item>
      <b-nav-item router-link :to="setNavPath('/dvirs')" class="position-relative py-2" link-classes="mx-auto">
        <div>
          <img src="/images/road.svg" :width="width" :height="height" alt="Dvirs" />
        </div>
        DVIR Defects
      </b-nav-item>

      <b-nav-item router-link :to="setNavPath('/units')" class="d-flex" link-classes="d-flex align-items-center">
        <div><img src="/images/wheel.svg" :width="width" :height="height" class="" alt="Units" /></div>
        Units
      </b-nav-item>

      <b-nav-item
        router-link
        :to="setNavPath('/service-orders')"
        class="d-flex"
        link-classes="d-flex align-items-center"
      >
        <div>
          <img src="/images/clipboard-white.svg" :width="width" :height="height" class="" alt="Service Orders" />
        </div>
        <div>Service Orders</div>
      </b-nav-item>
      <b-nav-item router-link :to="setNavPath('/estimates')" class="d-flex" link-classes="d-flex align-items-center">
        <div>
          <img src="/images/calculator.svg" :width="width" :height="height" class="" alt="Estimates" />
        </div>
        <div>Estimates</div>
      </b-nav-item>
      <b-nav-item router-link :to="setNavPath('/breakdowns')" class="py-2" link-classes="mx-auto">
        <div class="mb-1">
          <img src="/images/breakdown.svg" :width="width" :height="height" class="" alt="Breakdowns" />
        </div>
        Breakdowns
      </b-nav-item>
      <b-nav-item
        v-if="vcpServiceRequestFlagEnabled"
        router-link
        :to="setNavPath('/calendar')"
        class="py-2"
        link-classes="mx-auto"
      >
        <div class="mb-1">
          <font-awesome-icon :icon="['far', 'calendar']" size="lg" />
        </div>
        Service Calendar
      </b-nav-item>
      <b-nav-item v-if="$smallScreen && $environment" class="py-2 mt-auto border-0" link-classes="w-100">
        <h3>
          <b-badge variant="danger">{{ $environment }}</b-badge>
        </h3>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ConfigGetters } from '@/shared/store/config/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { RootGetters, RootMutations } from '@/shared/store/types';
import { SIDEBAR } from '@/shared/store/state';

export default {
  name: 'SidebarNav',
  components: {},
  data: function () {
    return {
      SIDEBAR: SIDEBAR,
      mouseEnterExpanded: false,
      width: 'auto',
      height: 22
    };
  },
  computed: {
    ...mapGetters([ConfigGetters.GET_CONFIG]),
    ...mapGetters({ isFeatureFlagEnabled: LookupGetters.IS_FEATURE_FLAG_ENABLED }),
    reportsEnabled() {
      return this[ConfigGetters.GET_CONFIG].powerBIReports.enabled;
    },
    vcpServiceRequestFlagEnabled() {
      return this.isFeatureFlagEnabled('VcpServiceRequest');
    },
    sidebar: {
      get() {
        return this.$store.getters[RootGetters.GET_SIDEBAR];
      },
      set(value) {
        this.$store.commit(RootMutations.SET_SIDEBAR, value);
      }
    }
  },
  methods: {
    toggleExpandCollapse() {
      if (this.mouseEnterExpanded) {
        this.sidebar = SIDEBAR.EXPANDED;
        this.mouseEnterExpanded = false;
      } else {
        this.sidebar = !this.sidebar;
      }
    },
    navbarMouseEnter() {
      if (this.sidebar === SIDEBAR.COLLAPSED) {
        this.sidebar = SIDEBAR.EXPANDED;
        this.mouseEnterExpanded = true;
      }
    },
    navbarMouseLeave() {
      if (this.mouseEnterExpanded) {
        this.sidebar = SIDEBAR.COLLAPSED;
        this.mouseEnterExpanded = false;
      }
    },
    clickNav() {
      if (this.$smallScreen) {
        this.sidebar = SIDEBAR.COLLAPSED;
      }
    },
    setNavPath(path) {
      const query = this.$route.path == path ? this.$route.query : null;
      return { path, query };
    }
  }
};
</script>
