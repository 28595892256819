import BreakdownPage from '@/shared/pages/breakdown/BreakdownPage';
import SearchBreakdownsPage from '@/shared/pages/breakdown/SearchBreakdownsPage';
import SearchEstimatesPage from '@/shared/pages/service-order/SearchEstimatesPage';
import LandingPage from '@/shared/pages/LandingPage';
import OperationsForecastPage from '@/shared/pages/operation-forecast/OperationsForecastPage';
import PowerBIReportsPage from '@/vcp/pages/PowerBIReportsPage';
import ServiceOrderPage from '@/shared/pages/service-order/ServiceOrderPage';
import SearchServiceOrdersPage from '@/shared/pages/service-order/SearchServiceOrdersPage';
import UnitDetailsPage from '@/shared/pages/unit/UnitDetailsPage';
import UnitPage from '@/shared/pages/unit/UnitPage';
import SearchUnitsPage from '@/shared/pages/unit/SearchUnitsPage';
import UnitServiceOrdersPage from '@/vcp/pages/unit/UnitServiceOrdersPage';
import UnitScheduledOperationsPage from '@/shared/pages/unit/UnitScheduledOperationsPage';
import InspectionPage from '@/shared/pages/inspection/InspectionPage';
import DeferredWork from '@/shared/pages/deferred-work/SearchDeferredWorkPage';
import SearchDvirDefectsPage from '@/shared/pages/unit/SearchDvirDefectsPage';
import EPayDeclinePage from '@/vcp/pages/EPay/EPayDeclinePage';
import EPayProcessPage from '@/vcp/pages/EPay/EPayProcessPage';

import VueRouter from 'vue-router';
import axios from 'axios';
import qs from 'qs';
// vuex
import { ConfigGetters } from '@/shared/store/config/types';
import { UserActions } from '@/shared/store/user/types';
import { LookupActions } from '@/shared/store/lookup/types';

// Router constants.
const LOGIN_PATH = '/login';
const DASHBOARD_PATH = '/dashboard';
const UNIT_PATH = '/units';
const OPS_FORECAST_PATH = '/operation-forecast';
const UNIT_PAGE_PATH = '/units/:unitId';
const UNIT_SERVICE_ORDERS_PATH = UNIT_PAGE_PATH + '/service-orders';
const SERVICE_ORDERS_SEARCH_PATH = '/service-orders';
const ESTIMATES_SEARCH_PATH = '/estimates';
const BREAKDOWN_SEARCH_PATH = '/breakdowns';
const UNIT_BREAKDOWN_PATH = UNIT_PAGE_PATH + '/breakdowns';
const UNIT_BREAKDOWN_DETAILS_PATH = UNIT_BREAKDOWN_PATH + '/(create)?:breakdownId?';
const INSPECTIONS_PATH = '/inspections';
const UNIT_INSPECTION_PATH = INSPECTIONS_PATH + '/:unitInspectionId';
const UNIT_SERVICE_ORDER_DETAILS_PATH = UNIT_SERVICE_ORDERS_PATH + '/:serviceOrderId';
const UNIT_STANDARD_OPERATIONS_PATH = UNIT_PAGE_PATH + '/scheduled-operations';
const SERVICE_CALENDAR_PATH = '/calendar';
const DEFERRED_WORK_PATH = '/deferred-work';
const DVIRS_PATH = '/dvirs';
const EPAY_DECLINE_PATH = '/payment-decline';
const EPAY_PROCESS_PATH = '/payment-process';

let DEFAULT_PATH = OPS_FORECAST_PATH;
const ERROR_PATH = '/error/:code?';

let routes = [
  {
    name: 'Dashboard',
    path: DASHBOARD_PATH,
    component: PowerBIReportsPage
  },
  {
    name: 'OperationsForecast',
    path: OPS_FORECAST_PATH,
    component: OperationsForecastPage
  },
  {
    name: 'UnitSearch',
    path: UNIT_PATH,
    component: SearchUnitsPage
  },
  {
    name: 'Unit',
    path: UNIT_PAGE_PATH,
    component: UnitPage,
    children: [
      {
        name: 'UnitDetails',
        path: UNIT_PAGE_PATH,
        component: UnitDetailsPage
      },
      {
        name: 'UnitServiceOrders',
        path: UNIT_SERVICE_ORDERS_PATH,
        component: UnitServiceOrdersPage
      },
      {
        name: 'UnitScheduledOperations',
        path: UNIT_STANDARD_OPERATIONS_PATH,
        component: UnitScheduledOperationsPage
      }
    ]
  },
  {
    name: 'ServiceOrders',
    path: SERVICE_ORDERS_SEARCH_PATH,
    component: SearchServiceOrdersPage
  },
  { name: 'Estimates', path: ESTIMATES_SEARCH_PATH, component: SearchEstimatesPage },
  {
    name: 'Breakdowns',
    path: BREAKDOWN_SEARCH_PATH,
    component: SearchBreakdownsPage
  },
  {
    name: 'Inspection',
    path: UNIT_INSPECTION_PATH,
    component: InspectionPage
  },
  {
    name: 'ServiceOrderDetails',
    path: UNIT_SERVICE_ORDER_DETAILS_PATH,
    component: ServiceOrderPage
  },
  {
    name: 'BreakdownDetails',
    path: UNIT_BREAKDOWN_DETAILS_PATH,
    component: BreakdownPage
  },
  {
    name: 'ServiceCalendar',
    path: SERVICE_CALENDAR_PATH,
    component: () => import('@/vcp/pages/service-calendar/ServiceCalendarPage')
  },
  {
    name: 'DeferredWork',
    path: DEFERRED_WORK_PATH,
    component: DeferredWork
  },
  {
    name: 'Dvirs',
    path: DVIRS_PATH,
    component: SearchDvirDefectsPage
  },
  {
    name: 'Login',
    path: LOGIN_PATH,
    meta: { hideSidebar: true },
    component: LandingPage
  },
  // Default path for now
  {
    name: 'Default',
    path: '*',
    meta: { hideSidebar: true },
    redirect: DEFAULT_PATH
  },
  {
    name: 'Error',
    path: ERROR_PATH,
    component: LandingPage
  },
  {
    name: 'payment-decline',
    path: EPAY_DECLINE_PATH,
    meta: { hideSidebar: true },
    component: EPayDeclinePage
  },
  {
    name: 'payment-process',
    path: EPAY_PROCESS_PATH,
    meta: { hideSidebar: true },
    component: EPayProcessPage
  }
];

class AppRouter {
  constructor(store) {
    this.store = store;

    const config = this.store.getters[ConfigGetters.GET_CONFIG];

    if (!config.powerBIReports.enabled) {
      routes = removeReportRoutes(routes);
    }

    this.router = new VueRouter({
      mode: 'history',
      routes: routes
    });

    const vm = this;

    axios.interceptors.request.use(
      async function (config) {
        config.url = '/public' + config.url;
        config.paramsSerializer = params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        };

        const token = await vm.router.app.$msal.getToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token.idToken}`;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const status = error.response?.status;
        if (status === 403) {
          vm.router.push({ name: 'Error', params: { code: error.response.status } });
          return Promise.reject(error);
        }

        if (status !== 401) {
          return Promise.reject(error);
        }

        if (
          vm.router.app._route.name != 'Login' &&
          vm.router.app._route.name != 'payment-decline' &&
          vm.router.app._route.name != 'payment-process'
        ) {
          vm.router.push({ name: 'Login', params: { logout: true } });
        }

        return Promise.reject(error);
      }
    );

    this.router.beforeEach(async (to, from, next) => {
      if (to.name === 'Error') {
        next();
        return;
      }

      if (to.name === 'payment-decline') {
        next();
        return;
      }

      if (to.name === 'payment-process') {
        next();
        return;
      }

      const isAuthenticated = this.router.app.$msal.isAuthenticated();

      if (to.fullPath === LOGIN_PATH) {
        if (to.params.logout) {
          await store.dispatch(UserActions.LOGOUT, false);
          this.router.app.$msal.logout();
          return;
        }

        if (!isAuthenticated) attemptSignIn(this);
        next();
        return;
      }

      if (isAuthenticated) {
        try {
          await store.dispatch(LookupActions.FETCH_LOOKUPS);
        } catch {
          next(false);
          return;
        }
      } else {
        attemptSignIn(this);
      }
      next();
    });
  }
}

function removeReportRoutes(routes) {
  DEFAULT_PATH = SERVICE_ORDERS_SEARCH_PATH;

  const defaultRoute = routes.find(route => route.name === 'Default');
  defaultRoute.redirect = SERVICE_ORDERS_SEARCH_PATH;

  return routes.filter(route => route.name !== 'Dashboard');
}

function attemptSignIn(vm) {
  try {
    return vm.router.app.$msal.signIn();
  } catch (error) {
    vm.app.$msalError = true;
    vm.app.$errorObject = error;
    vm.$router.push({ name: 'Error' });
  }
}

export default AppRouter;
