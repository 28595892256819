<template>
  <div>
    <h5 v-show="!isModal">Parts Request</h5>
    <h6 v-show="!isModal" class="text-uppercase">{{ estimorderDisplayText }} ID: {{ serviceOrderId }}</h6>
    <div v-if="loading" class="text-center py-4">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <div v-if="!isWarrantyAdmin">
        <b-row no-gutters>
          <b-col>
            <span class="required-legend float-right p-0">* Required</span>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col :class="{ 'col-8': isModal }">
            <b-form-group label="Request Type:" label-class="col-head" label-for="partsRequestTypeId">
              <b-input-group>
                <v-select
                  id="partsRequestTypeId"
                  v-model="$v.partsRequestTypeId.$model"
                  :state="!$v.partsRequestTypeId.$error ? null : false"
                  :options="filteredPartsRequestTypesList"
                  :reduce="partsRequestType => partsRequestType.id"
                  :clearable="false"
                  label="partsRequestTypeId"
                  select-on-tab
                  :class="`${$v.partsRequestTypeId.$error && !$v.partsRequestTypeId.required ? 'is-invalid' : ''}`"
                >
                  <template #option="type">
                    <span>{{ type.description }}</span>
                  </template>
                  <template #selected-option="type">
                    <span>{{ type.description }}</span>
                  </template>
                </v-select>
                <span class="required-asterisk">*</span>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="isModal" no-gutters>
          <b-col :class="{ 'col-8': isModal }">
            <b-form-group label="Branch:" label-class="col-head" label-for="branch">
              <b-row>
                <b-input-group class="d-flex">
                  <v-select
                    id="branch"
                    v-model="$v.branchId.$model"
                    :state="!$v.branchId.$error ? null : false"
                    :options="myBranches"
                    :reduce="branch => branch.branchId"
                    :clearable="false"
                    label="branchId"
                    select-on-tab
                    :class="`${$v.branchId.$error && !$v.branchId.required ? 'is-invalid' : ''}`"
                  >
                    <template #option="branch">
                      <span>{{ branch.branchId }} - {{ branch.branchName }}</span>
                    </template>
                    <template #selected-option="branch">
                      <span>{{ branch.branchId }} - {{ branch.branchName }}</span>
                    </template>
                  </v-select>
                  <span class="required-asterisk">*</span>
                </b-input-group>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row no-gutters>
          <b-col :class="{ 'col-8': isModal }">
            <b-form-group label="Delivery Method:" label-class="col-head" label-for="partsRequestDeliveryMethodId">
              <b-input-group>
                <v-select
                  id="partsRequestDeliveryMethodId"
                  v-model="$v.partsRequestDeliveryMethodId.$model"
                  :state="!$v.partsRequestDeliveryMethodId.$error ? null : false"
                  :options="deliveryMethodsList"
                  :reduce="partsRequestDeliveryMethod => partsRequestDeliveryMethod.id"
                  :clearable="false"
                  label="partsRequestDeliveryMethodId"
                  select-on-tab
                  :class="`${
                    $v.partsRequestDeliveryMethodId.$error && !$v.partsRequestDeliveryMethodId.required
                      ? 'is-invalid'
                      : ''
                  }`"
                >
                  <template #option="type">
                    <span>{{ type.description }}</span>
                  </template>
                  <template #selected-option="type">
                    <span>{{ type.description }}</span>
                  </template>
                </v-select>
                <span class="required-asterisk">*</span>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-for="(item, index) in requestParts" :key="index" no-gutters>
          <b-col>
            <requested-part
              ref="partinput"
              :key="`${index}`"
              :part="item"
              :index="index"
              :ops-id-list="opsIdList"
              :is-modal="isModal"
              :branch-id="branchId"
            ></requested-part>
          </b-col>
        </b-row>
        <b-row no-gutters>
          <b-col>
            <b-button class="add-part-button" variant="secondary" @click="addPart()">Add Another Part</b-button>
          </b-col>
        </b-row>

        <b-button v-if="!loading" variant="primary" class="mt-3" :disabled="sending" @click="send">
          <div v-show="sending">
            <b-spinner small></b-spinner>
            Sending...
          </div>
          <div v-show="!sending">Send Parts Request</div>
        </b-button>
      </div>

      <div v-show="!isModal" class="mt-4">
        <h5>Requested Parts</h5>
        <div v-if="requestedParts.length === 0 && !loading">No parts requested in VAMS</div>
        <b-table
          v-if="requestedParts.length > 0 && !loading"
          :items="requestedParts"
          :fields="fields"
          striped
          class="border"
          no-border-collapse
        >
          <template #head(_showDetails)>
            <div class="text-center">
              <div class="btn-icon" @click="toggleExpandCollapseAll()">
                <img :src="iconString" />
              </div>
            </div>
          </template>
          <template #cell(_showDetails)="data">
            <div v-if="data.item.requestParts.length > 2" class="text-center">
              <div class="btn-icon" @click="toggleDetails(data.index)">
                <font-awesome-icon v-if="showingDetails(data.index)" icon="minus" class="text-primary" />
                <font-awesome-icon v-else icon="plus" class="text-primary" />
              </div>
            </div>
          </template>
          <template #cell(partsRequestId)="{ item }">
            <div style="width: auto; display: inline-flex">
              <div>
                <safe-hyperlink :to="'/parts-requests/' + item.partsRequestId">
                  {{ item.partsRequestId }}
                </safe-hyperlink>
              </div>
              <b-badge class="mx-1" :variant="GetPartsRequestTypeClass(GetPartsRequestType(item.partsRequestTypeId))">
                {{ GetPartsRequestType(item.partsRequestTypeId) }}
              </b-badge>
            </div>
          </template>
          <template #cell(partsRequestStatus)="{ item, index }">
            <div style="white-space: nowrap">
              {{ GetPartsRequestStatus(item.partsRequestStatusId) }}
              <font-awesome-icon
                :id="`tool_tip_request_info_${index}`"
                icon="info-circle"
                class="mx-1 text-primary align-self-center"
                display="inline-block"
              />
              <b-tooltip :target="`tool_tip_request_info_${index}`" triggers="hover">
                <div style="text-align: left">
                  <div>Requested: {{ item.dateCreate | date }}</div>
                  <div>Estimated Delivery: {{ etaCheck(item) }}</div>
                  <div>Requested By: {{ item.requestorName }}</div>
                </div>
              </b-tooltip>
            </div>
          </template>
          <template #cell(requestParts)="data">
            <table-cell-list
              :ref="`requested-parts-${data.index}`"
              :row-index="data.index"
              :list="data.value"
              :show-more.sync="data.item._showDetails"
              @show="toggleDetails(item)"
            >
              <template #default="{ item: part }">
                <div>
                  <b>{{ part.partNumber }}</b>
                  <span class="text-muted">&nbsp;- {{ part.partDescription }}</span>
                </div>
              </template>
            </table-cell-list>
          </template>
        </b-table>

        <div class="py-4" />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import TableCellListComponent from '@/shared/components/TableCellListComponent';

// Vuex
import { UnitGetters } from '@/shared/store/unit/types';
import { ServiceOrderGetters } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { PartsRequestGetters, PartsRequestMutations, PartsRequestActions } from '@/shared/store/parts-request/types';
import { UserGetters } from '@/shared/store/user/types';

import { mapGetters, mapActions, mapMutations } from 'vuex';

import { required, requiredIf } from 'vuelidate/lib/validators';
import RequestedPart from '@/shared/pages/service-order/floating-actions/RequestedPartComponent.vue';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';
import vSelect from 'vue-select';
import moment from 'moment';

export default {
  name: 'PartsRequest',
  components: {
    'requested-part': RequestedPart,
    'safe-hyperlink': SafeHyperlinkComponent,
    'table-cell-list': TableCellListComponent,
    vSelect
  },
  props: {
    isModal: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      sending: false,
      loading: false,
      today: new Date(),
      isExpanded: false,
      expandAllIcon: '/images/expand-all.svg',
      collapseAllIcon: '/images/collapse-all.svg',
      iconString: null
    };
  },
  validations: {
    partsRequestTypeId: {
      required
    },
    partsRequestDeliveryMethodId: {
      required
    },
    branchId: {
      required: requiredIf(function () {
        return this.isModal;
      })
    }
  },
  computed: {
    ...mapGetters([UserGetters.GET_USER_PROFILE, ServiceOrderGetters.GET_BRANCH_ID, UserGetters.AUTHORIZE_ROLE]),
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      jobs: ServiceOrderGetters.GET_JOBS,
      partsRequest: PartsRequestGetters.GET_PARTS_REQUEST,
      requestParts: PartsRequestGetters.GET_REQUEST_PARTS,
      partsRequestTypesList: LookupGetters.GET_PARTS_REQUEST_TYPES_LIST,
      partsRequestStatusTypesUnsortedList: LookupGetters.GET_PARTS_REQUEST_STATUS_LIST_UNSORTED,
      requestedParts: PartsRequestGetters.GET_REQUESTED_PARTS,
      userProfile: UserGetters.GET_USER_PROFILE,
      deliveryMethodsList: LookupGetters.GET_PARTS_REQUEST_DELIVERY_METHODS_LIST
    }),
    filteredPartsRequestTypesList() {
      if (this.isModal) {
        return this.partsRequestTypesList?.filter(type => !type.showOnServiceOrder);
      } else {
        return this.partsRequestTypesList?.filter(type => type.showOnServiceOrder);
      }
    },
    isWarrantyAdmin() {
      return this[UserGetters.AUTHORIZE_ROLE](['WarrantyAdmin']);
    },
    requestParts: {
      get() {
        return this.partsRequest?.requestParts;
      }
    },
    opsIdList() {
      return Object.values(this.jobs).map(x => x.operationId);
    },
    partsRequestTypeId: {
      get() {
        return this.partsRequest?.partsRequestTypeId;
      },
      set(value) {
        this[PartsRequestMutations.SET_PROP]({ key: 'partsRequestTypeId', value });
      }
    },
    partsRequestDeliveryMethodId: {
      get() {
        return this.partsRequest?.partsRequestDeliveryMethodId;
      },
      set(value) {
        this[PartsRequestMutations.SET_PROP]({ key: 'partsRequestDeliveryMethodId', value });
      }
    },
    unitId() {
      return this.unit.unitId;
    },
    serviceOrderId() {
      return this.serviceOrder.serviceOrderId;
    },
    customerId() {
      return this.serviceOrder.customerId;
    },
    fields() {
      const sharedOptions = { sortable: true };
      const fields = [
        { key: '_showDetails', label: '', ...sharedOptions, thStyle: 'width: 50px' },
        { key: 'partsRequestId', label: 'Parts Request Id', ...sharedOptions },
        { key: 'partsRequestStatus', label: 'Status', ...sharedOptions },
        { key: 'assignedToName', label: 'Materials Associate', ...sharedOptions },
        { key: 'requestParts', label: 'Parts', ...sharedOptions }
      ];
      return fields;
    },
    disabledDates() {
      return { to: this.minimumDate };
    },
    minimumDate() {
      return moment(this.dateCreate).startOf('day').toDate();
    },
    myBranches() {
      return this.userProfile.employeeBranches;
    },
    branchId: {
      get() {
        if (this.isModal) {
          return this.partsRequest?.branchId;
        } else {
          return this[ServiceOrderGetters.GET_BRANCH_ID];
        }
      },
      set(value) {
        this[PartsRequestMutations.SET_PROP]({ key: 'branchId', value });
      }
    }
  },
  watch: {
    requestParts: {
      handler() {
        this.$nextTick(() => {
          for (let i = 0; i < this.requestParts?.length; i++) {
            this[PartsRequestMutations.SET_PART_PROP]({
              index: i,
              key: 'partNumber',
              value: this.requestParts[i]?.partNumber ?? ''
            });
            this[PartsRequestMutations.SET_PART_PROP]({
              index: i,
              key: 'partDescription',
              value: this.requestParts[i]?.partDescription ?? ''
            });
            if (!this.loading) this.$refs.partinput[i].errorReset();
          }
        });
      },
      immediate: true
    }
  },
  async beforeDestroy() {
    await this[PartsRequestActions.RESET_PARTS_REQUEST_FORM]();
  },
  async created() {
    this.loading = true;
    this.iconString = this.isExpanded ? this.collapseAllIcon : this.expandAllIcon;
    await this[PartsRequestActions.RESET_PARTS_REQUEST_FORM]();
    await this[PartsRequestActions.FETCH_REQUESTED_PARTS](this.serviceOrderId);
    if (this.requestParts.length == 0) {
      this.addPart();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions([
      PartsRequestActions.SEND_PARTS_REQUEST,
      PartsRequestActions.FETCH_REQUESTED_PARTS,
      PartsRequestActions.RESET_PARTS_REQUEST_FORM
    ]),
    ...mapMutations([
      PartsRequestMutations.SET_PROP,
      PartsRequestMutations.SET_PART_PROP,
      PartsRequestMutations.ADD_REQUEST_PART,
      PartsRequestMutations.REMOVE_REQUEST_PART,
      PartsRequestMutations.RESET_STATE,
      PartsRequestMutations.SET_SHOW_DETAILS
    ]),
    setDefaultParams() {
      this[PartsRequestMutations.SET_PROP]({ key: 'unitId', value: this.unit.unitId });
      this[PartsRequestMutations.SET_PROP]({ key: 'branchId', value: this.branchId });
      this[PartsRequestMutations.SET_PROP]({ key: 'slsId', value: this.serviceOrder.serviceOrderId });
    },
    toggleDetails(index) {
      this.$refs[`requested-parts-${index}`].setShowMore(!this.showingDetails(index));
    },
    toggleExpandCollapseAll() {
      this.isExpanded = !this.isExpanded;
      this.setAllShowMores(this.isExpanded);
      this.iconString = this.isExpanded ? this.collapseAllIcon : this.expandAllIcon;
    },
    setAllShowMores(value) {
      for (let i = 0; i < this.requestedParts.length; i++) {
        this.$refs[`requested-parts-${i}`].setShowMore(value);
      }
    },
    showingDetails(index) {
      return this.$refs[`requested-parts-${index}`]?.showMore;
    },
    etaCheck(item) {
      var etaReturn = item.requestParts[0].eta;
      item.requestParts.forEach(i => {
        if (etaReturn != i.eta) {
          etaReturn = 'Multiple';
        }
      });
      var eta = etaReturn instanceof Date ? moment(etaReturn).format('MM/DD/YYYY') : etaReturn;
      return eta;
    },
    async send() {
      this.$v.$touch();
      let incompleteParts = [];
      for (let i = 0; i < this.requestParts?.length; i++) {
        if (this.$refs.partinput[i].errorCheck()) {
          incompleteParts.push(this.$refs.partinput[i].errorCheck());
        }
      }
      if (this.$v.$anyError || incompleteParts?.length > 0) {
        ErrorService.createErrorToast(this, 'Error sending Parts Request. See indicated fields below.');
        return;
      }
      try {
        this.sending = this.loading = true;
        if (this.serviceOrder.serviceOrderId != null) {
          await this.setDefaultParams();
        }
        this.partsRequest.customerId = this.customerId;
        let savedPartsRequestId = await this[PartsRequestActions.SEND_PARTS_REQUEST](this.partsRequest);
        await this[PartsRequestActions.FETCH_REQUESTED_PARTS](this.serviceOrderId);
        await this[PartsRequestActions.RESET_PARTS_REQUEST_FORM]();

        this.$v.$reset();
        for (let i = 0; i < this.requestParts; i++) {
          this.$refs.partinput[i].errorReset();
        }
        SuccessService.createSuccessToast(this.$root, `Parts Request #${savedPartsRequestId} sent successfully.`);
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to send Parts Request.');
      } finally {
        this.sending = this.loading = false;
      }
    },
    cancel() {
      this.$bvModal.hide(`createPartsRequestModal`);
    },
    addPart() {
      this[PartsRequestMutations.ADD_REQUEST_PART]();
      this.$nextTick(() => {
        let index = this.requestParts.length - 1;
        this.$refs.partinput[index].setPartNumberFocus();
      });
    },
    GetPartsRequestType(id) {
      var type = this.partsRequestTypesList.filter(type => type.id == id);
      return type[0].description;
    },
    GetPartsRequestStatus(id) {
      var type = this.partsRequestStatusTypesUnsortedList.filter(type => type.id == id);
      return type[0].description;
    },
    GetPartsRequestTypeClass(type) {
      switch (type) {
        case 'SO Quote':
          return 'danger';
        case 'FC Quote':
          return 'danger';
        case 'Stock Quote':
          return 'danger';
        case 'Supply Quote':
          return 'danger';
      }
    }
  }
};
</script>
<style scoped>
.add-part-button {
  width: 100%;
  border-color: #cccccc;
  border-top-color: white;
  border-radius: 0%;
}
.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
  /* border-color: rgba(0, 0, 0, 0.25); */
  background-color: #ffffff;
  border-width: 1px;
}
</style>
